 import Etsy from "assets/etsy.svg"

export const BusinessesAndOrganizations = [
  {
    url: "https://tomedes.gumlet.io/our-customers/Google%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Volkswagen%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/DHL.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Hilton.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Coca%20Cola.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Warner%20Music%20Group.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Johnson%20&%20Johnson%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Amazon%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Medtronic.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Youtube.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Symantec%20logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/British%20Airways%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Strauss_Group_Logo%20logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/JCB%20logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Maersk%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Keller_Williams_Realty_logo%20logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/ExxonMobil%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Warner%20Bros.%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/BNP%20Paribas%20Group%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/NEC.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/NVIDIA%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Oracle%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Canon%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Steve%20Madden.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Kiwi.com%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Hitachi%20Logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Crane_Co._logo%20logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/SAP%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/BBC%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/metro-goldwyn-mayer.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Decathlon%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Discovery%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Nestl%E2%80%9A%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/TEDX%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/United%20Nations.svg",
  },
  { url: "https://tomedes.gumlet.io/our-customers/British%20Red%20Cross.svg" },
];

export const HighTechData = [
  {
    url: "https://tomedes.gumlet.io/our-customers/Youtube.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/checkpoint%20logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Google%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/yelp.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Amazon%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Cellebrite%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/amdocs.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/WIX.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/3M.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/HP.svg",
  },
  {
    url: "https://tomedes.gumlet.io/frontend/images/customer-logos/hightech-internet/spotify.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Verifone.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/motorola%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Symantec%20logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Rakuten.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Broadcom%20Inc.%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Microsoft%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Ahrefs%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Salesforce.svg",
  },
];

export const LawAndFinanceData = [
  {
    url: "https://tomedes.gumlet.io/our-customers/CREDIT%20SUISSE.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/HSBC.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/PERKINS%20COIE.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/GREENBERG%20TRAURIG.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/MONEYGRAM.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/howden.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/KPMG.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/BLOOMBERG.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/kane-russell-coleman-logan.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/EY.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/RBC.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/NEW%20YORK%20LIFE.svg",
  },
];
export const AcademyData = [
  {
    url: "https://tomedes.gumlet.io/our-customers/GEORGETOWN%20UNIVERSITY.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/STANFORD.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/University%20of%20London.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/yale-signature.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UNIVERSITY%20OF%20CHICAGO.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/CORNELL%20UNIVERSITY.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UNIVERSITY%20OF%20MINNESOTA.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/WEIZMANN%20INSTITUDE%20OF%20SCIENCE.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UNIVERSITY%20OF%20ARKANSAS.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UNIVERSITY%20OF%20MANCHESTER.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UNIVERSITY%20OF%20WASHINGTON.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UNIVERSITY%20OF%20COLORADO%20COLORADO%20SPRINGS.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UNIVERSITY%20OF%20NORTH%20TEXAS.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/TECHNION%20-%20ISRAEL%20INSTITUTE%20OF%20TECHNOLOGY.svg",
  },
];

export const AppsData = [
  {
    url: "https://tomedes.gumlet.io/our-customers/NVIDIA%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/SNAPCHAT.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Mixcloud.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/ZEMINGO.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/TINY%20LABS%20PRODUCTION%20-%20BLACK%201.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/LUPA.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/TAB%20TALE.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/newton.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/MOBISYSTEMS.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/playscape.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/DELIVEROO.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/webpals.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/TONARA.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/mag.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/CYBERGHOST.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UMAKE.svg",
  },
];
export const AllData = [
  {
    url: "https://tomedes.gumlet.io/our-customers/Google%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Volkswagen%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/DHL.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Hilton.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Coca%20Cola.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Warner%20Music%20Group.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Johnson%20&%20Johnson%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Amazon%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Medtronic.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Youtube.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Symantec%20logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/British%20Airways%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Strauss_Group_Logo%20logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/JCB%20logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Maersk%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Keller_Williams_Realty_logo%20logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/ExxonMobil%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Warner%20Bros.%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/BNP%20Paribas%20Group%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/NEC.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/NVIDIA%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Oracle%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Canon%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Steve%20Madden.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Kiwi.com%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Hitachi%20Logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Crane_Co._logo%20logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/SAP%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/BBC%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/metro-goldwyn-mayer.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Decathlon%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Discovery%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Nestl%E2%80%9A%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/TEDX%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/United%20Nations.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/British%20Red%20Cross.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/checkpoint%20logo.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Google%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/yelp.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Cellebrite%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/amdocs.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/WIX.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/3M.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/HP.svg",
  },
  {
    url: "https://tomedes.gumlet.io/frontend/images/customer-logos/hightech-internet/spotify.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Verifone.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/motorola%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Rakuten.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Broadcom%20Inc.%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Microsoft%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Ahrefs%20svg.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Salesforce.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/CREDIT%20SUISSE.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/HSBC.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/PERKINS%20COIE.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/GREENBERG%20TRAURIG.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/MONEYGRAM.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/howden.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/KPMG.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/kane-russell-coleman-logan.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/BLOOMBERG.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/EY.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/RBC.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/NEW%20YORK%20LIFE.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/GEORGETOWN%20UNIVERSITY.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/STANFORD.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/University%20of%20London.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/yale-signature.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UNIVERSITY%20OF%20CHICAGO.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/CORNELL%20UNIVERSITY.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UNIVERSITY%20OF%20MINNESOTA.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/WEIZMANN%20INSTITUDE%20OF%20SCIENCE.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UNIVERSITY%20OF%20ARKANSAS.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UNIVERSITY%20OF%20MANCHESTER.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UNIVERSITY%20OF%20WASHINGTON.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UNIVERSITY%20OF%20COLORADO%20COLORADO%20SPRINGS.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UNIVERSITY%20OF%20NORTH%20TEXAS.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/TECHNION%20-%20ISRAEL%20INSTITUTE%20OF%20TECHNOLOGY.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/SNAPCHAT.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/Mixcloud.svg",
  },

  {
    url: "https://tomedes.gumlet.io/our-customers/ZEMINGO.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/TINY%20LABS%20PRODUCTION%20-%20BLACK%201.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/LUPA.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/TAB%20TALE.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/newton.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/MOBISYSTEMS.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/playscape.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/DELIVEROO.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/webpals.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/TONARA.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/mag.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/CYBERGHOST.svg",
  },
  {
    url: "https://tomedes.gumlet.io/our-customers/UMAKE.svg",
  },
  {
    url: Etsy,
  }
];
